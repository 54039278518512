 .policyDetailsTable table{
overflow-x: auto;
width: 100% !important;
 }
.policyDetailsTable img{
   max-width: 100% !important;
    height: auto;
}
@media screen and (max-width: 480px) {
   .policyDetailsTable table th,.policyDetailsTable table td{
   word-break: break-all;
   width: auto !important;
   }
   .policyDetailsTable table colgroup{
      word-break: break-all;
      width: auto !important;
      }
   }
.hospicashPolicyDetails table{
   overflow-x: auto;
width: 100% !important;
margin-left: 0pt !important;
}
@media screen and (max-width: 480px) {
   .hospicashPolicyDetails table th,.hospicashPolicyDetails table td{
   word-break: break-all;
   width: auto !important;
   
   }
   .hospicashPolicyDetails .Table {
      margin-left: auto;
   }
 }
.hospicashPolicyDetails img{
   max-width: 100% !important;
   height: auto;
}

.policyDetailsVB table{
   overflow-x: auto;
   width: 100% !important;
   
}
.policyDetailsVB img{
   max-width: 100% !important;
    height: auto;
}
@media screen and (max-width: 480px) {
   .policyDetailsVB table th,.policyDetailsVB table td{
   word-break: break-all;
   width: auto !important;
   }
   .policyDetailsVB table colgroup{
      word-break: break-all;
      width: auto !important;
      }
   }



   .MuiTabs-flexContainer{
      flex-wrap: wrap !important;
   }
   
   .tappMartLogoStyle{
      height: 40;
      margin-top: 6px;
      margin-bottom: 6px;
   }
   .tappMartLogoStylePlan{
      height: 40;
      margin-top: 6px;
      margin-bottom: -4px;
   }
   .tappMartLogoStyleHS{
      height: 40;
      margin-top: 6px;
      margin-bottom: 6px;
   }
   .tappMartLogoStyleHC{
      height: 40;
     margin-top: 6px;
     margin-bottom: 6px;
   }
   html body{
      overflow-x: hidden;
   }